// Schema.org plugin for Nuxt
export default defineNuxtPlugin((nuxtApp) => {
  // Website schema for the whole site
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Hitung Harga Jual",
    "url": "https://www.hitung-harga-jual.com",
    "description": "Kalkulator gratis untuk bisnis kuliner menentukan harga jual optimal di GoFood, GrabFood, dan ShopeeFood, dengan memperhitungkan komisi platform dan biaya lainnya.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.hitung-harga-jual.com/cari?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    "inLanguage": "id-ID"
  };

  // Organization schema
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Hitung Harga Jual",
    "url": "https://www.hitung-harga-jual.com",
    "logo": "https://www.hitung-harga-jual.com/logo.png",
    "inLanguage": "id-ID"
  };

  // Helper function to add schema to a page
  const addSchema = (schema) => {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(schema)
        }
      ]
    });
  };

  // Make schemas and helper function available
  return {
    provide: {
      schema: {
        website: websiteSchema,
        organization: organizationSchema,
        addSchema
      }
    }
  };
}); 