<script setup lang="ts">
useHead({
  title: 'Hitung Harga Jual Online',
  meta: [
    {
      name: 'description',
      content: 'Temukan kemudahan menghitung harga jual dengan alat online kami. Mempermudah strategi penetapan harga bisnis Anda dan maksimalkan keuntungan dengan mudah. Ideal untuk pengusaha dan bisnis kecil yang ingin mengoptimalkan pendekatan penjualan mereka.'
    },
    {
      name: 'google-adsense-account',
      content: 'ca-pub-7524918493048672'
    },
    {
      name: 'google-site-verification',
      content: 'DCDaj-Kz3HKkel0fYpoHs3u5D_Fy8t8YVvgbEkeDrHc'
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Hitung Harga Jual",
        "url": "https://www.hitung-harga-jual.com",
        "description": "Kalkulator gratis untuk bisnis kuliner menentukan harga jual optimal di GoFood, GrabFood, dan ShopeeFood, dengan memperhitungkan komisi platform dan biaya lainnya.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.hitung-harga-jual.com/cari?q={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "inLanguage": "id-ID"
      })
    },
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Hitung Harga Jual",
        "url": "https://www.hitung-harga-jual.com",
        "logo": "https://www.hitung-harga-jual.com/logo.png",
        "inLanguage": "id-ID"
      })
    }
  ]
})
</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>